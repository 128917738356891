// Mixins Import //
@import "../mixins/_mixins";


//////////////////////////////////////////////
///////////////      PAGE      ///////////////
//////////////////////////////////////////////

#Page {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: hsl(0,0%,90%, 0);
    color: hsl(0,0%,10%);
    overflow: hidden;
    white-space: nowrap;
    transition-property: background-color;
    transition-duration: .9s;
    transition-timing-function: cubic-bezier(0.1,0.4,0.2,1);
}

.is_dark {
    background-color:hsl(0, 0%, 10%)!important;
    color:hsl(0, 0%, 90%)!important;
}

//////////////////////////////////////////////
///////////////      HOME      ///////////////
//////////////////////////////////////////////

.home {
    position: absolute;
    right: calc($pad*2);
    bottom: calc($pad*2);
    white-space: nowrap;
    &_aboutme {
        line-height: 1.5;
        & span {
            display: block;
            font-family: 'NeueMontrealLight', sans-serif;;
            font-size: 15px;
            font-weight: 600;
        }
    }
}

////////////////////////////////////////////////////
///////////////      BACKGROUND      ///////////////
////////////////////////////////////////////////////

#Background {
    position: fixed;
    z-index: 1;
    left: $pad;
    right: $pad;
    top: $pad;
    bottom: $pad;
    pointer-events: none;
    overflow: hidden;
}

//////////////////////////////////////////////
///////////////      MASK      ///////////////
//////////////////////////////////////////////

.mask {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.mask_bottom {
    bottom:0;
}

.mask_top {
    top:0;
}

.mask_bottom, .mask_top {
    position: absolute;
    left: 0;
    width: 100%;
    height: $pad;
    background-color: inherit;
    transition-property: background-color;
    transition-duration: .9s;
    transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
    opacity: .9;
}

.dark_mask{
    background-color: hsl(0, 0%, 10%);
}
.light_mask{
    background-color: hsl(0, 0%, 90%);
}

///////////////////////////////////////////////
///////////////      FRAME      ///////////////
///////////////////////////////////////////////

.frame {
    position: fixed;
    z-index: 10;
    left: $pad;
    right: $pad;
    top: $pad;
    bottom: $pad;
    mix-blend-mode: difference;
    pointer-events: none;
}

.frame_line {
    position: absolute;
    opacity: .5;
}

.darken_border {
    background-color: hsl(0, 0%, 10%);
}

.lighten_border {
    background-color: hsl(0, 0%, 90%);
}
.frame_line_left {
    left: 0;
    top:0;
    width: 1px;
    height: 100%;
}

.frame_line_right {
    right: 0;
    top: 0;
    width: 1px;
    height: 100%;
}

.frame_line_top {
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
}

.frame_line_bottom {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
}

///////////////////////////////////////////////
///////////////      THEME      ///////////////
///////////////////////////////////////////////

.theme {
    position: fixed;
    z-index: 10;
    left: calc(20px - 0.15em);
    bottom: $pad;
    transform-origin: left bottom;
    transform: rotate(-90deg);
    white-space: nowrap;
    mix-blend-mode: difference;
    display: flex;
    column-gap: 15px;
    cursor: pointer;
    &_color{
        pointer-events: auto;
        cursor: crosshair;
        font-size:15px;
        display: flex;
        column-gap: 3px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.theme_btn.is-selected ._box::before {
    content: "■";
    font-size: 20px;
    margin: 0 2px;
}

.theme_btn ._box::before {
    content: "□";
    font-size: 20px;
    margin: 0 2px;
}


.theme_color {
    .light_btn {
        // background-color:hsl(0, 0%, 90%);
        color:hsl(0, 0%, 10%);
        transition-property: background-color;
        transition-duration: .9s;
        transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
    }
    .dark_btn {
        // background-color:hsl(0, 0%, 10%);
        color:hsl(0, 0%, 90%);
        transition-property: background-color;
        transition-duration: .9s;
        transition-timing-function: cubic-bezier(0.1, 0.4, 0.2, 1);
    }
}

.dark_btn, .light_btn{
    display: flex;
    align-items: baseline;
    text-transform: uppercase;
    margin: 0 3px;
}

@media (min-width: 1280px){
    .theme {
        left: calc(30px - 0.15em);
    }
}

//////////////////////////////////////////////////////
////////////////      SITE HEADER      ///////////////
//////////////////////////////////////////////////////

.siteHeader {
    position: fixed;
    z-index: 10;
    left: calc($pad*2);
    top: calc($pad*2);
    mix-blend-mode: difference;
}

.siteHeader_title {
    margin: -0.1em 0 0 -0.04em;
    font-weight: 200;
    font-size: 30px;
}

.siteHeader_label {
    margin-top: 10px; 
    font-weight: 200;
}

.siteHeader_nav {
    margin-top: 50px;
    line-height: 2.2rem;
    list-style: none;
}

@media (min-width: 1280px){
    .siteHeader_title {
        font-size: 55px;
    }
    .siteHeader_nav .siteHeader_label {
        margin-left: 6px;
    }
}

.nav-item {
    cursor:crosshair;
}

.nav-item:focus{
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

/////////////////////////////////////////////////
///////////////      CONTENT      ///////////////
/////////////////////////////////////////////////

.content {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    mix-blend-mode: difference;
}

.content_inner {
    position: relative;
    min-height:100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
}

//////////////////////////////////////////////////
///////////////      PROJECTS      ///////////////
//////////////////////////////////////////////////

.page {
    width: 100%;
    min-height: calc(var($vh) * 100);
}

.projects {
    padding: 0 calc($pad*2);
    text-align: right;
    white-space: nowrap;
    position:fixed;
    right: 0;
}

@media (min-width: 1280px){
    .projects {
        padding: 0 calc($pad*2);
    }
}

.project_list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 15px;
    margin-top: 50px;
}


@media(hover: hover) {
    .text-btn:hover {
        opacity: .25;
    }
}

.project_heading {
    font-weight: 700;
    font-size: inherit;
}
.project_box {
    font-size: 25px;
}

.project_list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 15px;
    margin-top: 50px;
}

@media (min-width: 1280px){
    .project_title {
        font-weight: 200;
        font-size: 30px;
        text-align: right;
    }
    .project_item {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: baseline;
        column-gap: 15px;

    }
}


.text-btn {
    transition-property: opacity;
    transition-duration: .6s;
    transition-timing-function: cubic-bezier(.1, .4, .2, 1)
}

.project_title {
    font-weight: 200;
    font-size: 30px;
    text-align: right;
}


.project_info {
    margin-top: 5px;
    font-weight: 700;
}

@media screen and (max-width: 415px){
    .project_title {
        font-weight: 200;
        font-size: 20px !important;
        text-align: right;
    }
    .project_info {
        font-size: 13px !important;
    }
    .project_heading {
        font-weight: 700;
        font-size: 15px;
    }
    .project_list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        row-gap: 15px;
        margin-top: 25px;
    }
    .project_box {
        font-size: 20px;
    }
}

@media (min-width: 1280px) {
    .project_title {
        font-size: 60px!important;
    }
}

@media (min-width: 1280px) {
    .project_info {
        margin-top: 0;
        font-weight: 700;
    }
}

//////////////////////////////////////////////////
///////////////      CONTACTS      ///////////////
//////////////////////////////////////////////////

.contacts {
    position: absolute;
    left: calc($pad*2);
    bottom: calc($pad*2);
    white-space: nowrap;
}


.contact_links, .affiliated_links {
    line-height: 1.5;
    display: flex;
    flex-direction: column;
}

@media (min-width: 1280px) {
    .contacts {
        position: relative;
        left: auto;
        bottom: auto;
        height: 100vh;
    }
    .contact_links {
        margin-top: 0;
        left: calc($pad *2);
        bottom: calc($pad *2);
    }
    .affiliated_links {
        right: calc($pad *2);
        top: calc($pad *2);       
    }
    .contact_links, .affiliated_links {
        position: absolute;
    }
}